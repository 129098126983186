import Swal from "sweetalert2";
import UtilService from "./UtilService";
import ReactDOMServer from "react-dom/server";
import TermsOfUseContent from "../data/TermsOfUseContent";
import SignaturePad from 'signature_pad';
import { useEffect, useState, type FC } from "react";
import { Form } from "react-bootstrap";
import { toast, Slide } from "react-toastify";

const UtilServiceReact = () => {

    const alertClasses = {
        customClass: {
            container: 'swal-custom-container',
            popup: 'swal-custom-popup',
        },
        showClass: {
            popup: 'my-custom-show-animation',
        },
        hideClass: {
            popup: 'my-custom-hide-animation',
        },
    };

    const showWarningAlert = (title: string, description: string) => {
        return Swal.fire({
            title: `${title}`,
            text: `${description}`,
            icon: 'warning',
            confirmButtonText: "OK",
            cancelButtonText: "CANCEL",
            showCancelButton: true,
            confirmButtonColor: "#04dd70",
            cancelButtonColor: "#f77369",
            ...alertClasses
        });
    }

    const showSuccessAlert = (title: string, description: string) => {
        return Swal.fire({
            title: `${title}`,
            text: `${description}`,
            icon: "success",
            confirmButtonText: "Confirm",
            cancelButtonText: "CANCEL",
            showCancelButton: true,
            confirmButtonColor: "#04dd70",
            cancelButtonColor: "#f77369",
            ...alertClasses
        });
    }

    const showTitleAlert = (title: string, description: string, duration?: number) => {
        return Swal.fire({
            title: title,
            text: description,
            confirmButtonColor: "#04dd70",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            timer: duration ?? 4000,
            timerProgressBar: true,
            ...alertClasses
        })
    }

    const showTextConfirmationAlert = (title: any, description: string, key: string, showSignatureCanva = false) => {
        return Swal.fire({
            title: `${title}`,
            html: `${description}`,
            icon: 'warning',
            input: "text",
            inputAttributes: {
                autocapitalize: "true",
            },
            showCancelButton: true,
            confirmButtonText: "Confirm",
            confirmButtonColor: "#04dd70",
            cancelButtonColor: "#f77369",
            showLoaderOnConfirm: true,
            preConfirm: (valueTyped) => {
                if (valueTyped?.trim().toLowerCase() !== key.toLowerCase()) {
                    Swal.showValidationMessage("Typed value does not match the expected key: " + key);
                    return;
                }
                if (showSignatureCanva) {
                    return true; // We simply return true here to indicate readiness for the next step
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
            ...alertClasses
        });
    };

    const showTextConfirmationAlertInputText = (title: any, description: string) => {
        return Swal.fire({
            title: `${title}`,
            html: `${description}`,
            icon: 'warning',
            input: "text",
            inputAttributes: {
                autocapitalize: "true",
            },
            showCancelButton: true,
            confirmButtonText: "Confirm",
            confirmButtonColor: "#04dd70",
            cancelButtonColor: "#f77369",
            showLoaderOnConfirm: true,
            preConfirm: (valueTyped) => {
                if (valueTyped?.trim() === "") {
                    Swal.showValidationMessage("The input cannot be empty.");
                    return null;
                }
                return valueTyped;
            },
            allowOutsideClick: () => !Swal.isLoading(),
            ...alertClasses
        });
    };

    const showSignaturePad = () => {
        return Swal.fire({
            title: 'Digital Signature',
            html: `
                <h5>Draw your personal signature and confirm<h5>
                <div class="m-auto text-center">
                    <canvas id="signature-pad" class="signature-pad" width="350" height="150" style="border: 1px solid; border-radius: 15px;"></canvas>
                    <div class="btn-group w-100" role="group">
                    <button id="eraseButton" class="btn btn-outline-pronto" style="margin-top: 10px;">Clear</button>
                    <button id="saveJPGButton" class="btn btn-outline-pronto" style="margin-top: 10px;" data-action="save-jpg">Confirm signature</button>
                    </div>
                </div>
                <input type="hidden" id="hfSign" />`,
            focusConfirm: false,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Close',
            confirmButtonColor: "#04dd70",
            cancelButtonColor: "#f77369",
            icon: 'warning',
            didOpen: () => {
                const canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
                const signaturePad = new SignaturePad(canvas, {
                    backgroundColor: 'rgb(255, 255, 255)'
                });

                (canvas as any).signaturePad = signaturePad;

                document.getElementById('eraseButton')?.addEventListener('click', (e) => {
                    signaturePad.clear();
                    e.preventDefault();
                });

                document.getElementById('saveJPGButton')?.addEventListener('click', function () {
                    if (signaturePad.isEmpty()) {
                        Swal.showValidationMessage("Please provide a signature.");
                    } else {
                        var dataURL = signaturePad.toDataURL("image/jpeg");
                        var blob = dataURLToBlob(dataURL);
                        Swal.close({ isConfirmed: true, value: blob });
                    }
                });
            },
            ...alertClasses
        });
    };

    function dataURLToBlob(dataURL: any) {
        var parts = dataURL.split(';base64,');
        var contentType = parts[0].split(":")[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    function download(dataURL: any, filename: any) {
        var blob = dataURLToBlob(dataURL);
        var url = window.URL.createObjectURL(blob);
        var a: any = document.createElement("a");
        a.style = "display: none";
        a.href = url;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }



    const showTextSimpleInput = (title: string, description: string, validationErrorMsg: string, inputValue: string, inputType: any = 'text') => {
        return Swal.fire({
            title: `${title}`,
            html: `${description}`,
            icon: 'warning',
            input: inputType,
            inputAttributes: {
                autocapitalize: "true",
            },
            showCancelButton: true,
            confirmButtonText: "Confirm",
            confirmButtonColor: "#04dd70",
            cancelButtonColor: "#f77369",
            showLoaderOnConfirm: true,
            inputValue: inputValue,
            preConfirm: (valueTyped: string) => {
                if (valueTyped) {
                    return valueTyped?.trim();
                } else {
                    Swal.showValidationMessage(validationErrorMsg);
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
            ...alertClasses
        })
    }

    const showSuccessTimerAlert = (title: string, description: string, duration?: number) => {
        return Swal.fire({
            title: title,
            text: description,
            icon: "success",
            confirmButtonColor: "#04dd70",
            confirmButtonText: "Got it",
            allowOutsideClick: false,
            timer: duration ?? 1000,
            timerProgressBar: true,
            ...alertClasses
        })
    }

    const showSuccessSimpleToast = (title: string) =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">{title}</p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 1000,
                theme: "colored",
            }
        );

    const showWarningTimerAlert = (title: string, description: string, duration?: number) => {
        return Swal.fire({
            title: title,
            text: description,
            icon: "warning",
            confirmButtonColor: "#04dd70",
            confirmButtonText: "Got it",
            allowOutsideClick: false,
            timer: duration ?? 1000,
            timerProgressBar: true,
            ...alertClasses
        })
    }

    const emailValidations = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let message: string;
        if (!emailRegex.test(email)) {
            message = 'Required. Please enter a valid email address.';
            return { msg: message, isValid: false }
        } else {
            return { msg: '', isValid: true }
        }
    };

    const phoneValidations = (phone: string) => {
        if (phone) {
            let message: string;
            const phoneDigits = phone?.replace(/\D/g, ''); // Remove non-digit characters
            const phoneRegex = /^\d{10}$/; // 10-digit format
            if (!phoneRegex.test(phoneDigits)) {
                message = 'Required. Please enter a valid 10-digit phone number.';
                return { msg: message, isValid: false }
            } else {
                return { msg: '', isValid: true }
            }
        } else {
            return { msg: "Required.", isValid: false }
        }
    }

    const requiredTextValidations = (text: string) => {
        let message: string;
        if (!text) {
            message = 'Required.';
            return { msg: message, isValid: false }
        }
        return { msg: '', isValid: true }
    };

    const validateEmailList = (emailNotifications: any[]) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; 
        return (
            Array.isArray(emailNotifications) &&
            emailNotifications.length > 0 &&
            emailNotifications.every((email: string) => emailRegex.test(email))
        );
    };


    const requiredPositiveValueValidations = (value: number) => {
        let message: string;
        if (value <= 0) {
            message = 'Must be greater than 0.';
            return { msg: message, isValid: false }
        }
        return { msg: '', isValid: true }
    };

    const contractAmount = (items: any[], serviceOrder: any, applyDiscount: boolean = true) => {
        const baseCost = builderCost(items, serviceOrder, false);
        const profit = profitValue(items, serviceOrder, applyDiscount);
        return baseCost + profit;
    };

    const builderCost = (
        items: any[],
        serviceOrder: any,
        isCalcMarkup: boolean = true
    ) => {

        const totalCost = items?.reduce((accumulator: number, item: any) => {
            const baseCost = item.unitCost * item.quantity || 0;
            const markupCost = isCalcMarkup ? baseCost * item.markup : 0;
            const taxCost = serviceOrder?.tax && item.applyTax ? ((baseCost + markupCost) * (serviceOrder?.tax / 100) || 0) : 0;
            return accumulator + baseCost + markupCost + taxCost;
        }, 0) || 0;

        return totalCost;
    };

    const builderCostExtraWorkOrders = (extraWorkOrders: any[], serviceOrder: any) => {
        const extraWorkOrderItems = extraWorkOrders?.flatMap(extraWorkOrder => extraWorkOrder.items);
        return extraWorkOrderItems?.length > 0 ? builderCost(extraWorkOrderItems, serviceOrder, true) : 0;
    };

    const profitValue = (items: any[], serviceOrder: any, applyDiscount: boolean = true) => {
        const totalWithMarkup = builderCost(items, serviceOrder, true);
        const totalWithoutMarkup = builderCost(items, serviceOrder, false);
        let profit = (totalWithMarkup || 0) - (totalWithoutMarkup || 0);

        if (applyDiscount) {
            profit = profit - (serviceOrder?.discount || 0);
        }

        return profit || 0;
    };

    const profitValueChangeOrder = (extraWorkOrders: any[], serviceOrder: any) => {
        if (extraWorkOrders?.length > 0) {
            const extraWorkOrderItems = extraWorkOrders?.flatMap(extraWorkOrder => extraWorkOrder?.items).filter((item: any) => item.status === 'APPROVED');
            const builderCostWithMarkup = builderCost(extraWorkOrderItems, serviceOrder);
            const builderCostWithoutMarkup = builderCost(extraWorkOrderItems, serviceOrder, false);
            const profitValue = (builderCostWithMarkup || 0) - (builderCostWithoutMarkup || 0);
            return profitValue || 0;
        } else {
            return 0;
        }
    }

    const priceItemString = (item: any, serviceOrder: any) => {
        const baseCost = item.unitCost * (item.quantity || 1) || 0;
        const markupCost = baseCost * item.markup;
        const taxCost = item.applyTax ? (baseCost * (serviceOrder?.tax / 100) || 0) : 0;
        const unitCostPlusTaxCost = baseCost + markupCost + taxCost;
        const formattedSingleUnitCostWithMarkup = numberToFixed2(unitCostPlusTaxCost);
        const totalCost = builderCost([item], serviceOrder, true);
        const formattedTotalCost = numberToFixed2(totalCost);
        return `Price: $${formattedSingleUnitCostWithMarkup}<br>Qty: ${item.quantity}<br>Total: $${formattedTotalCost}`;
    };

    const priceItemOptionString = (item: any, serviceOrder: any) => {
        const baseCost = item.unitCost * (item.quantity || 1) || 0;
        const formattedSingleUnitCostWithMarkup = numberToFixed2(baseCost);
        const totalCost = builderCost([item], serviceOrder, true);
        const formattedUnitCost = numberToFixed2(item.unitCost);
        return `Price: $${formattedUnitCost}<br>Qty: ${item.quantity}<br>Total: $${formattedSingleUnitCostWithMarkup}`;
    };

    const costsItemOrderObject = (unitCost: number, quantity: number, markup: number, applyTax: boolean, tax: number) => {
        if (unitCost < 0 || quantity < 0 || markup < 0 || tax < 0) {
            throw new Error("Invalid negative value provided");
        }

        let builderCost: number = (unitCost * quantity) || 0;
        let profitAmount: number = builderCost * markup || 0;

        const taxCost = applyTax ? parseFloat(((builderCost + profitAmount) * (tax / 100)).toFixed(2)) : 0;

        let ownerPrice = parseFloat((builderCost + (profitAmount)).toFixed(2)) || 0;
        if (applyTax) {
            ownerPrice += taxCost;
        }

        return { builderCost, profitAmount, ownerPrice };
    }


    const calculateTotalInvoiceValue = (invoiceHistory: any[]) => {
        return invoiceHistory?.reduce((accumulator, item) => accumulator + (item.invoiceValue || 0), 0);
    }

    const getItemsNotInvoicedYet = (items: any[], serviceOrder: any) => {
        const itemsNotInvoicedYet = items?.filter((item: any) => {
            const invoicedValue = calculateTotalInvoiceValue(item?.invoiceHistory) || 0;
            const itemBuilderCost = builderCost([item], serviceOrder);
            return invoicedValue < itemBuilderCost;
        });
        return itemsNotInvoicedYet || [];
    }

    const getItemsNotInvoicedYetExtraWorkOrders = (extraWorkOrders: any[], serviceOrder: any) => {
        if (extraWorkOrders?.length > 0) {
            const extraWorkOrderItems = extraWorkOrders?.flatMap(extraWorkOrder => extraWorkOrder?.items);
            const itemsNotInvoicedYet = extraWorkOrderItems?.filter((item: any) => {
                const invoicedValue = calculateTotalInvoiceValue(item?.invoiceHistory) || 0;
                const itemBuilderCost = builderCost([item], serviceOrder);
                return invoicedValue < itemBuilderCost;
            });
            return itemsNotInvoicedYet || [];
        } else {
            return [];
        }
    }

    const getTotalApprovedServiceOrders = (items: any[]) => {
        if (items?.length > 0) {
            const serviceOrderItems = items?.filter((item: any) => item.status === 'APPROVED');
            return serviceOrderItems?.length;
        } else {
            return 0;
        }
    }

    const getTotalApprovedExtraWorkOrders = (extraWorkOrders: any[]) => {
        if (extraWorkOrders?.length > 0) {
            const extraWorkOrderItems = extraWorkOrders?.filter((extraWorkOrder: any) => extraWorkOrder.status === 'APPROVED');
            return extraWorkOrderItems?.length;
        } else {
            return 0;
        }
    }


    const totalInvoicedItemsRef = (items: any[], ref: any, type: string) => {
        if (items?.length > 0) {
            const totalInvoiced = items
                .filter((historyItem: any) => historyItem?.invoiceRef == ref)
                .reduce((accumulator, historyItem) => accumulator + (historyItem?.invoiceValue || 0), 0);
            return totalInvoiced || 0;
        }
        return 0;
    };


    const totalInvoicedNotPaidItems = (items: any[]) => {
        if (items) {
            const totalInvoiced = items
                .filter((item: any) => item?.invoiceHistory)
                .flatMap((item: any) => item?.invoiceHistory || [])
                .filter((historyItem: any) => !historyItem.isPaid)
                .reduce((accumulator, historyItem) => accumulator + (historyItem.invoiceValue || 0), 0);
            return totalInvoiced || 0;
        }
        return 0;
    }

    const totalInvoicedPaidItems = (items: any[]) => {
        if (items) {
            const totalPaidInvoices = items
                .filter((item: any) => item?.invoiceHistory)
                .flatMap((item: any) => item?.invoiceHistory || [])
                .filter((historyItem: any) => historyItem.isPaid)
                .reduce((accumulator, historyItem) => accumulator + (historyItem.invoiceValue || 0), 0);
            return totalPaidInvoices || 0;
        }
        return 0;

    }

    const totalInvoicedNotPaidItemsExtraWorkOrders = (extraWorkOrders: any[]) => {
        if (extraWorkOrders?.length > 0) {
            const extraWorkOrderItems = extraWorkOrders?.flatMap(extraWorkOrder => extraWorkOrder?.items);
            const totalPaidInvoices = extraWorkOrderItems
                .filter((item: any) => item?.invoiceHistory)
                .flatMap((item: any) => item?.invoiceHistory || [])
                .filter((historyItem: any) => !historyItem.isPaid)
                .reduce((accumulator, historyItem) => accumulator + (historyItem.invoiceValue || 0), 0);
            return totalPaidInvoices || 0;
        }
        return 0;
    }

    const totalInvoicedPaidItemsExtraWorkOrders = (extraWorkOrders: any[]) => {
        if (extraWorkOrders?.length > 0) {
            const extraWorkOrderItems = extraWorkOrders?.flatMap(extraWorkOrder => extraWorkOrder?.items);
            const totalPaidInvoices = extraWorkOrderItems
                .filter((item: any) => item?.invoiceHistory)
                .flatMap((item: any) => item?.invoiceHistory || [])
                .filter((historyItem: any) => historyItem.isPaid)
                .reduce((accumulator, historyItem) => accumulator + (historyItem.invoiceValue || 0), 0);
            return totalPaidInvoices || 0;
        }
        return 0;
    }

    /*
    const invoiceHistoryList = (items: any[]) => {
        if (items) {
            const serviceOrderHistoryList = items
            .filter((item: any) => item?.invoiceHistory)
            .map((item: any) => item?.invoiceHistory)
            .reduce((accumulator: any, historyArray: []) => accumulator.concat(historyArray), []);
            return serviceOrderHistoryList;
        }
        return [];
    }
    */

    const invoiceHistoryList = (items: any[]) => {
        if (items) {
            const serviceOrderHistoryList = items
                .filter((item: any) => item?.invoiceHistory)
                .map((item: any) => item?.invoiceHistory)
                .reduce((accumulator: any, historyArray: []) => accumulator.concat(historyArray), []);

            // Group by invoiceRef
            const groupedInvoiceHistory = serviceOrderHistoryList.reduce((result: any, invoiceItem: any) => {
                const invoiceRef = invoiceItem.invoiceRef;
                if (!result[invoiceRef]) {
                    result[invoiceRef] = [];
                }
                result[invoiceRef].push(invoiceItem);
                return result;
            }, {});

            // Transform into array with ref and items
            const invoiceHistoryArray = Object.keys(groupedInvoiceHistory).map((invoiceRef) => {
                return { ref: parseInt(invoiceRef), items: groupedInvoiceHistory[invoiceRef] };
            });

            return invoiceHistoryArray;
        }
        return [];
    };

    const invoiceHistoryListExtraWorkOrder = (extraWorkOrders: any[]) => {
        if (extraWorkOrders?.length > 0) {
            const extraWorkOrderItems = extraWorkOrders?.flatMap(extraWorkOrder => extraWorkOrder.items);
            const serviceOrderHistoryList = extraWorkOrderItems
                .filter((item: any) => item?.invoiceHistory)
                .map((item: any) => item?.invoiceHistory)
                .reduce((accumulator: any, historyArray: []) => accumulator.concat(historyArray), []);

            // Group by invoiceRef
            const groupedInvoiceHistory = serviceOrderHistoryList.reduce((result: any, invoiceItem: any) => {
                const invoiceRef = invoiceItem.invoiceRef;
                if (!result[invoiceRef]) {
                    result[invoiceRef] = [];
                }
                result[invoiceRef].push(invoiceItem);
                return result;
            }, {});

            // Transform into array with ref and items
            const invoiceHistoryArray = Object.keys(groupedInvoiceHistory).map((invoiceRef) => {
                return { ref: parseInt(invoiceRef), items: groupedInvoiceHistory[invoiceRef] };
            });

            return invoiceHistoryArray;
        }
        return [];
    };


    const changeOrderTotal = (extraWorkOrders: any[], serviceOrder: any) => {
        if (extraWorkOrders?.length > 0) {
            const extraWorkOrderItems = extraWorkOrders?.flatMap(extraWorkOrder => extraWorkOrder.items).filter((item: any) => item.status === 'APPROVED');
            return extraWorkOrderItems?.length > 0 ? builderCost(extraWorkOrderItems, serviceOrder) : 0;
        }
        return 0;
    }

    const calculateTaxValue = (items: any[], tax: number) => {
        return items?.filter((item: any) => item.applyTax)
            .reduce((acc: number, item: any) => {
                const itemTaxRate = tax > 0 ? tax / 100 : 0;
                const valuePlusMarkup = item.unitCost * (1 + item.markup) * item.quantity;
                return acc + (valuePlusMarkup * itemTaxRate);
            }, 0) || 0;
    };

    const calculateTotals = (items: any[], serviceOrder: any, discount: number) => {
        const subtotalWithMarkup = builderCost(items, serviceOrder);
        const taxValue = calculateTaxValue(items, serviceOrder?.tax);
        const discountValue = discount || 0;
        const totalValue = subtotalWithMarkup + taxValue - discountValue;

        return {
            subtotalWithMarkup,
            taxValue,
            discountValue,
            totalValue
        };
    };

    const validatePositiveValue = (value: number) => {
        return value > 0 ? value : 0;
    }

    const validateEmptyText = (value: string) => {
        return UtilService.isEmpty(value) ? "" : value;
    }

    const numberToFixed2 = (value: number) => {
        return parseFloat(value.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2 });
    }

    const termsOfUse = () => {
        const termsOfUseHtml = ReactDOMServer.renderToStaticMarkup(<TermsOfUseContent />);
        Swal.fire({
            title: 'Terms of Use',
            html: termsOfUseHtml,
            width: 600,
            padding: '3em',
            backdrop: `
            rgba(0,0,123,0.4)
            url("/images/nyan-cat.gif")
            left top
            no-repeat
            `,
            ...alertClasses
        });
    }

    const errorMessage: FC<{ error: string }> = ({ error }) => { // Correct destructuring
        const [showError, setShowError] = useState(!!error);

        useEffect(() => {
            let timeoutId: ReturnType<typeof setTimeout>; // More precise type for timeoutId

            if (showError) {
                timeoutId = setTimeout(() => {
                    setShowError(false);
                }, 5000);
            }

            return () => clearTimeout(timeoutId);
        }, [showError]);

        return showError ? (
            <Form.Text className='font-weight-semibold' style={{ color: "#ff0000" }}>
                {error}
            </Form.Text>
        ) : null;
    };

    const useDebounce = (value: any, delay: number) => {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

    return {
        showWarningAlert,
        showWarningTimerAlert,
        showSuccessAlert,
        showSuccessTimerAlert,
        showSuccessSimpleToast,
        showTitleAlert,
        showTextConfirmationAlert,
        showTextSimpleInput,
        emailValidations,
        phoneValidations,
        requiredTextValidations,
        contractAmount,
        builderCost,
        builderCostExtraWorkOrders,
        profitValue,
        profitValueChangeOrder,
        costsItemOrderObject,
        validatePositiveValue,
        validateEmptyText,
        requiredPositiveValueValidations,
        changeOrderTotal,
        totalInvoicedItemsRef,
        totalInvoicedNotPaidItems,
        totalInvoicedPaidItems,
        totalInvoicedNotPaidItemsExtraWorkOrders,
        totalInvoicedPaidItemsExtraWorkOrders,
        invoiceHistoryList,
        invoiceHistoryListExtraWorkOrder,
        calculateTotals,
        calculateTotalInvoiceValue,
        getItemsNotInvoicedYet,
        getItemsNotInvoicedYetExtraWorkOrders,
        numberToFixed2,
        priceItemString,
        priceItemOptionString,
        termsOfUse,
        getTotalApprovedServiceOrders,
        getTotalApprovedExtraWorkOrders,
        showSignaturePad,
        errorMessage,
        showTextConfirmationAlertInputText,
        useDebounce,
        validateEmailList
    }

}

export default UtilServiceReact;